// NPM
import { graphql, useStaticQuery } from 'gatsby'

export const useNavigationData = (): Queries.NavigationDataQuery => {
  const data = useStaticQuery(graphql`
    query NavigationData {
      mainNavigation: wpMenu(locations: { eq: PRIMARY }) {
        id
        name
        menuItems {
          nodes {
            id
            url
            title
            target
            path
            label
            cssClasses
            connectedNode {
              node {
                id
                nodeType
                ... on WpLava_product {
                  id
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      secondaryNavigation: wpMenu(locations: { eq: SECONDARY }) {
        id
        name
        menuItems {
          nodes {
            id
            url
            title
            target
            path
            label
            cssClasses
          }
        }
      }
      socialsNavigation: wpMenu(locations: { eq: SOCIALS }) {
        id
        name
        menuItems {
          nodes {
            id
            url
            title
            target
            path
            parentId
            label
            description
            cssClasses
          }
        }
      }
      privcyNavigation: wpMenu(locations: { eq: PRIVACY }) {
        id
        name
        menuItems {
          nodes {
            id
            url
            title
            target
            path
            parentId
            label
            description
            cssClasses
          }
        }
      }
    }
  `)
  return data
}
