// NPM
import React, { useEffect, useRef, useContext } from 'react'
import { useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import classNames from 'classnames'

// CONTEXT
import { GlobalContext } from '../context/globalContext'

// COMPONENTS
import Header from './Header'
import Footer from './Footer'
import Spinner from '../components/Spinner'
import PageTransition from '../components/PageTransition'
import PingPongBalls from '../components/PingPongBalls'

// TYPES
import type { LayoutProps, GlobalContextProps } from '../types/custom-types'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const Layout: React.FC<LayoutProps> = ({
  children
}) => {
  const location = useLocation()
  const { loaderOn }: GlobalContextProps = useContext(GlobalContext)

  const isComingSoonPage = location.pathname === '/coming-soon/'

  const main = useRef<HTMLDivElement | null>(null)
  const smoother = useRef<ScrollSmoother | null>(null)

  useGSAP(
    () => {
      smoother.current = ScrollSmoother.create({
        smooth: 2,
        speed: 0.8,
        effects: false,
        normalizeScroll: true,
        ignoreMobileResize: true
      })
    },
    { scope: main }
  )

  return (
    <>
      <Helmet>
        <body className={classNames(
          { 'overflow-hidden': loaderOn }
        )} />
      </Helmet>

      {!isComingSoonPage
        ? (
          <>
            <Header />

            <main id="smooth-wrapper" ref={main}>
              <div id="smooth-content" className="flex flex-col w-full min-h-screen">

                <PageTransition>
                  {children}
                </PageTransition>

                <Footer />
              </div>
            </main>

            {/* <PingPongBalls /> */}

            {loaderOn && <Spinner/>}
          </>
          )
        : (children)}
    </>
  )
}

export default Layout
