// NPM
import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import classnames from 'classnames'

// CONTEXT
import { GlobalContext } from '../context/globalContext'

// ASSETS
import MenuTogglerLine from '../images/menutogglerline-black.inline.svg'

const MenuToggler: React.FC = () => {
  const { isMenuOpen, toggleMenu } = useContext(GlobalContext)

  const menuTogglerTimelineRef = useRef<gsap.core.Timeline | null>(null)
  const menuTogglerLine1Ref = useRef<HTMLDivElement>(null)
  const menuTogglerLine2Ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (menuTogglerTimelineRef.current !== null) {
      isMenuOpen ? menuTogglerTimelineRef.current.play() : menuTogglerTimelineRef.current.timeScale(1.5).reverse()
    }
  }, [isMenuOpen])

  useEffect(() => {
    const timeline = gsap.timeline({
      paused: true,
      reversed: true
    })

    timeline.to(menuTogglerLine1Ref.current, { top: '50%', y: '-50%', duration: 0.2 })
    timeline.to(menuTogglerLine2Ref.current, { bottom: '50%', y: '+50%', duration: 0.2 }, 0)

    timeline.to(menuTogglerLine1Ref.current, { rotate: 135, duration: 0.5 }, 0.1)
    timeline.to(menuTogglerLine2Ref.current, { rotate: -135, duration: 0.5 }, 0.1)

    menuTogglerTimelineRef.current = timeline
  }, [])

  return (
      <button className={
        classnames(
          'menu-toggler',
          'right-0',
          'z-50',
          'transition',
          'transition-all',
          'duration-500',
          'ease-in-out',
          'my-auto'
        )}
        onClick={toggleMenu}
      >
        <span className="menu-toggler-inner block w-10 h-4 relative">
          <span ref={menuTogglerLine1Ref} className="absolute block top-0 right-0 w-full">
            <MenuTogglerLine className="w-full h-auto" />
          </span>
          <span ref={menuTogglerLine2Ref} className="absolute block bottom-0 right-0">
            <MenuTogglerLine className="w-full h-auto" />
          </span>
        </span>
      </button>
  )
}

export default MenuToggler
