// NPM
import React, { useState, useRef, useEffect, useCallback, createContext, type ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

// TYPES
import type { GlobalContextProps } from '../types/custom-types'

export const GlobalContext = createContext<GlobalContextProps>({
  bodyOverflow: false,
  isMenuOpen: false,
  isScrolled: false,
  isScrollingDown: false,
  loaderOn: false,
  setLoaderOn: () => {},
  toggleMenu: () => {},
  openMenu: () => {},
  closeMenu: () => {},
  disableBodyScroll: () => {}
})

const GlobalProvider = function ({ children }: { children: ReactNode }): JSX.Element {
  const [loaderOn, setLoaderOn] = useState<boolean>(false)
  const [bodyOverflow, setBodyOverflow] = useState<boolean>(true)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [isScrollingDown, setIsScrollingDown] = useState<boolean>(false)
  const previousScrollPositionRef = useRef<number>(0)
  const scrolledThreshold = 50

  const toggleMenu = (): void => {
    setIsMenuOpen((prev) => !prev)
  }

  const openMenu = (): void => {
    setIsMenuOpen(true)
  }

  const closeMenu = (): void => {
    setIsMenuOpen(false)
  }

  const disableBodyScroll = (): void => {
    setBodyOverflow(false)
  }

  const handleScroll = useCallback((): void => {
    const scrollTop = window.scrollY
    setIsScrolled(scrollTop > scrolledThreshold)
    setIsScrollingDown(scrollTop > previousScrollPositionRef.current)
    previousScrollPositionRef.current = scrollTop
  }, [])

  useEffect(() => {
    if (loaderOn || isMenuOpen) {
      setBodyOverflow(true)
    } else {
      setBodyOverflow(false)
    }
  }, [loaderOn, isMenuOpen])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <GlobalContext.Provider value={{
      bodyOverflow,
      isMenuOpen,
      isScrolled,
      isScrollingDown,
      loaderOn,
      setLoaderOn,
      toggleMenu,
      openMenu,
      closeMenu,
      disableBodyScroll
    }}>
      <Helmet htmlAttributes={{ lang: 'en' }} />
      <Helmet>
        <body className={classNames(
          { scrolled: isScrolled },
          { 'scrolling-down': isScrollingDown },
          { 'overflow-hidden': bodyOverflow }
        )} />
      </Helmet>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
