// NPM
import React, { useEffect, createContext, type ReactNode } from 'react'
import { Helmet } from 'react-helmet'

// TYPES
import type { CookiesContextProps } from '../types/custom-types'

export const CookiesContext = createContext<CookiesContextProps>({
  showCookiePolicy: () => {},
  openPreferences: () => {},
  showTcfVendors: () => {},
  resetCookies: () => {}
})

const CookiesProvider = function ({ children }: { children: ReactNode }): JSX.Element {
  const showCookiePolicy = (): void => {
    (window as any)._iub.cs.api.showCP()
  }

  const openPreferences = (): void => {
    (window as any)._iub.cs.api.openPreferences()
  }

  const getPreferences = (): any => {
    return (window as any)._iub.cs.api.getPreferences()
  }

  const isPreferenceExpressed = (): boolean => {
    return (window as any)._iub.cs.api.isPreferenceExpressed()
  }

  const showTcfVendors = (): void => {
    (window as any)._iub.cs.api.showTcfVendors()
  }

  const resetCookies = (): void => {
    (window as any)._iub.cs.api.resetCookies()
  }

  const giveConsent = (): void => {
    (window as any)._iub.cs.api.consentGiven()
  }

  const isConsentGiven = (): boolean => {
    return (window as any)._iub.cs.api.isConsentGiven()
  }

  const activateSnippets = (): void => {
    (window as any)._iub.cs.api.activateSnippets()
  }

  const gdprApplies = (): boolean => {
    return (window as any)._iub.cs.api.gdprApplies()
  }

  const ccpaApplies = (): boolean => {
    return (window as any)._iub.cs.api.ccpaApplies()
  }

  const acceptAll = (): void => {
    (window as any)._iub.cs.api.acceptAll()
  }

  const rejectAll = (): void => {
    (window as any)._iub.cs.api.rejectAll()
  }

  const handleConsentGiven = (): void => {
    const consent = isConsentGiven()
    console.log('handleOnConsentGiven called')
    console.log('isConsentGiven', consent)
    activateSnippets()
  }

  const handleConsentRejected = (): void => {
    const consent = isConsentGiven()
    console.log('handleOnConsentRejected called')
    console.log('isConsentGiven', consent)
  }

  const handleCookiePreferences = (): void => {
    const cookiePreferences = getPreferences()
    console.log('handleCookiePreferences called')
    console.log('cookiePreferences', cookiePreferences)
  }

  useEffect(() => {
    (window as any)._iub = (window as any)._iub || [];
    (window as any)._iub.csConfiguration = {
      askConsentAtCookiePolicyUpdate: true,
      countryDetection: true,
      enableFadp: true,
      enableLgpd: true,
      enableUspr: true,
      lgpdAppliesGlobally: false,
      perPurposeConsent: true,
      siteId: 3430867,
      cookiePolicyId: 68418870,
      lang: 'en-GB',
      cookiePolicyUrl: 'https://www.lava-project.com/cookie-policy/',
      privacyPolicyUrl: 'https://www.lava-project.com/privacy-policy/',
      privacyPolicyNoticeAtCollectionUrl: 'https://www.lava-project.com/notice-at-collection/',
      banner: {
        acceptButtonCaptionColor: '#2B2B2B',
        acceptButtonColor: '#F5F3EC',
        acceptButtonDisplay: true,
        closeButtonDisplay: false,
        customizeButtonColor: '#333333',
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        linksColor: '#F5F3EC',
        position: 'float-bottom-center',
        rejectButtonCaptionColor: '#2B2B2B',
        rejectButtonColor: '#F5F3EC',
        rejectButtonDisplay: true,
        showTitle: false,
        textColor: '#F5F3EC'
      },
      callback: {
        onConsentGiven: handleConsentGiven,
        // onConsentFirstGiven: handleConsentGiven,
        onConsentRejected: handleConsentRejected,
        // onConsentFirstRejected: handleConsentRejected,
        onPreferenceExpressed: handleCookiePreferences
      }
    }
  }, [])

  return (
    <CookiesContext.Provider value={{
      showCookiePolicy,
      openPreferences,
      showTcfVendors,
      resetCookies
    }}>
      <Helmet>
        <script type="text/javascript" src="https://cs.iubenda.com/autoblocking/3576396.js" />
        <script type="text/javascript" src="//cdn.iubenda.com/cs/gpp/stub.js" />
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          // eslint-disable-next-line react/no-unknown-property
          charSet="UTF-8"
        />
      </Helmet>

      {children}
    </CookiesContext.Provider>
  )
}

export default CookiesProvider
