// NPM
import React from 'react'
import classNames from 'classnames'

interface SpinnerProps {
  zIndex?: number
  mode?: 'dark' | 'light'
}

const Spinner: React.FC<SpinnerProps> = ({
  zIndex = 9999,
  mode = 'light'
}) => {
  const style = {
    zIndex
  }
  return (
    <div className={
      classNames(
        'spinner',
        'fixed',
        'top-0',
        'left-0',
        'right-0',
        'bottom-0',
        'opacity-80',
        {
          'bg-black': mode === 'dark',
          'bg-white': mode === 'light'
        }
      )
    } style={style}>
      <div className={
        classNames(
          'spinner__animation',
          {
            'text-white': mode === 'dark',
            'text-black': mode === 'light'
          }
        )
      }>
        {Array.from({ length: 4 }).map((_, i) => (
          <div key={i} className={
            classNames(
              {
                'border-white': mode === 'dark',
                'border-black': mode === 'light'
              }
            )
          }></div>
        ))}
      </div>
  </div>
  )
}
export default Spinner
