import React, { createContext, useState } from 'react'

import type { FC, ReactNode } from 'react'

interface TransitionContextType {
  completed: boolean
  toggleCompleted: (value: boolean) => void
}

interface TransitionProviderProps {
  children: ReactNode
}

const TransitionContext = createContext<TransitionContextType>({
  completed: false,
  toggleCompleted: () => {}
})

const TransitionProvider: FC<TransitionProviderProps> = ({
  children
}: TransitionProviderProps) => {
  const [completed, setCompleted] = useState<boolean>(false)

  const toggleCompleted = (value: boolean): void => {
    setCompleted(value)
  }

  return (
    <TransitionContext.Provider
      value={{
        toggleCompleted,
        completed
      }}
    >
      {children}
    </TransitionContext.Provider>
  )
}

export default TransitionContext
