// NPM
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// CONTEXT
import CookiesProvider from './src/context/cookiesContext'
import GlobalProvider from './src/context/globalContext'

// COMPONENTS
import Layout from './src/components/Layout'

// CSS
import './src/styles/main.scss'

// TYPES
import { type GatsbyBrowser } from 'gatsby'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  const reCaptchaKey = process.env.GATSBY_GRECAPTCHA_KEY ?? ''

  return (
    <CookiesProvider>
      <GlobalProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={reCaptchaKey}
          language="en"
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined
          }}
        >
          {element}
        </GoogleReCaptchaProvider>
      </GlobalProvider>
    </CookiesProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  // console.log('wrapPageElement', element, props)
  return (
    <Layout {...props}>{element}</Layout>
  )
}

// export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
// }
