// NPM
import React, { useContext } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import gsap from 'gsap'
import { useLocation } from '@reach/router'

// CONTEXT
import TransitionContext from '../context/transitionContext'

// TYPES
import type { ReactNode } from 'react'

interface PageTransitionProps {
  children: ReactNode
}

const PageTransition = ({ children }: PageTransitionProps): JSX.Element => {
  const location = useLocation()
  const { toggleCompleted } = useContext(TransitionContext)
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node: HTMLElement) => {
          toggleCompleted(false)
          gsap.set(node, {
            autoAlpha: 0
          })
          gsap
            .timeline({
              paused: true,
              onComplete: () => {
                toggleCompleted(true)
              }
            })
            .to(node, {
              autoAlpha: 1,
              duration: 0.3
            })
            .play()
        }}
        onExit={(node) => {
          gsap
            .timeline({ paused: true })
            .to(node, {
              autoAlpha: 0,
              duration: 0.3
            })
            .play()
        }}
      >
        <div className="flex flex-grow w-full">
          {children}
        </div>
      </Transition>
    </SwitchTransition>
  )
}

export default PageTransition
